<template>
  <div style="width: 100%;">
    <el-row :span="24">
      <el-scrollbar max-height="95dvh" style="width: 100%;">
        <el-main style="width: 100%;">
          <el-row>
            <el-col :span="11" class="pe-2">
              <el-card shadow="always" class="landing-page-map">
                <template #header>
                  <strong>Portfolio map viewer</strong> <small><i>(Click a property to show risk scores)</i></small>
                </template>
                <LandingPageMap />
              </el-card>
            </el-col>
            <el-col :span="13">
              <el-card shadow="always" class="mb-2">
                <template #header><strong>Choose your assessment:</strong></template>
                <el-row :gutter="12" class="mt-2 mb-2">
                  <el-col :span="8" v-for="item in raapComponents" :key="item.id" >
                    <el-card shadow="always"
                      body-style="position: relative;"
                      :style="{ height: '25vh', backgroundImage: `url(/${item.id}_menu.png)`, backgroundSize: 'cover', backgroundPosition: 'center'}">
                        <el-button class="menu-view-btn" type="warning" @click="reroute(item.id)" round>VIEW</el-button>
                    </el-card>
                  </el-col>
                </el-row>
              </el-card>

              <el-row :gutter="12">
                <el-col>
                  <el-card shadow="always" style="width: 100%">
                    <template #header><strong>The Risk and Adaptation Platform provides the following features across each assessment:</strong></template>
                    <div style="text-align: center;">
                      <el-image src="HomePageDiagram_v2.png" style="height: 300px" />
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

        </el-main>
      </el-scrollbar>
    </el-row>
  </div>
</template>

<script>
/*eslint-disable */
import {
  Suitcase,
  LocationInformation,
  School
} from '@element-plus/icons-vue'
import { ref } from 'vue'
import LandingPageMap from './LandingPageMap.vue'

export default {
  name: 'Home',
  components: {
    Suitcase,
    LocationInformation,
    School,
    LandingPageMap,
  },
  data() {
    return {
      activeAboutAccordion: ref('1'),
      raapComponents: [
        {
          id: "enterprise",
          label: "ENTERPRISE",
          icon_src: LocationInformation,
        },
        {
          id: "portfolio",
          label: "PORTFOLIO",
          icon_src: Suitcase,
        },
        {
          id: "campus",
          label: "CAMPUS",
          icon_src: School,
        }
      ]
    }
  },
  methods: {
    reroute: function (item) {
      this.$store.commit('mainModule/setCurrentAssessment', item)
      this.$router.push({ path: '/' + item })
    }
  }
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}

.arc-orange-font {
  color: #E4610F
}

.arc-light-orange-font {
  color: #EB894B
}

.arc-primary-font {
  color: #0C0808
}

.image {
  /* width: 100%; */
  margin-left: auto;
  margin-right: auto;
  height: 20vh;
  width: 20vw;
  display: block;
  border-radius: 1%;
}

.landing-page-map {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

::v-deep .landing-page-map .el-card__body {
  height: 100%;
}

.menu-view-btn {
  position: absolute;
  top: 20vh;
  background-color: #ED7A22;
  border-color: #ED7A22;
  color: white;
}

.menu-view-btn:hover {
  background-color: #f39044;
  border-color: #f39044;
  color: white;
}

.el-card__header .no-padding-header-y {
  padding-bottom: 0;
}
</style>