<template>
  <el-table
    :data="pageSelect"
    :height="windowHeight * screenFactor - 21"
    header-cell-class-name="custom-tbl-header"
    size="small"
    style="width: 100%"
    v-loading="loadState"
    :row-class-name="tableRowClassName"
    cell-class-name="text-break"
  >
    <el-table-column prop="id" label="ID" width="50" v-loading="loadState" fixed="left"></el-table-column>
    <el-table-column prop="parent_risk_statement"
      v-if="type !== 'enterprise'"
      label="Parent"
      width="230"
      fixed="left"
      v-loading="loadState"
    ></el-table-column>
    <el-table-column prop="region_label"
      v-if="type === 'campus'"
      label="Region"
      width="70"
      fixed="left"
      v-loading="loadState"
    >
      <template #default="scope">
        <el-select
         @change="handleRegionChange(scope.row)"
         v-model="scope.row.region_code" v-if="scope.row.isEdit" placeholder="Select" size="small" collapse-tags collapse-tags-tooltip>
          <el-option v-for="item in regionsList" :key="item.code" :label="item.label" :value="item.code" />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column prop="campus_label"
      v-if="type === 'campus'"
      label="Campus"
      width="120"
      fixed="left"
      v-loading="loadState"
    >
      <template #default="scope">
        <el-select v-model="scope.row.campus_code" v-if="scope.row.isEdit" placeholder="Select" size="small" collapse-tags collapse-tags-tooltip>
          <el-option v-for="item in getFilteredCampuses(scope.row.region_code)" :key="item.code" :label="item.label" :value="item.code" />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column prop="climate_variables_labels"
      v-if="type !== 'enterprise'"
      label="Climate Hazard"
      width="190"
      fixed="left"
      v-loading="loadState"
    >
      <template #default="scope">
        <el-select v-model="scope.row.climate_variables" v-if="scope.row.isEdit" placeholder="Select" size="small" multiple collapse-tags collapse-tags-tooltip>
          <el-option v-for="item in climateHazardsList" :key="item.code" :label="item.label" :value="item.code" />
        </el-select>
        <template v-else>
          <el-tag
            class="m-1"
            type="primary"
            effect="plain"
            size="small"
            v-for="label in scope.row.climate_variables_labels"
            :key="label"
          >
            {{ label }}
          </el-tag>
        </template>
      </template>
    </el-table-column>
    <el-table-column prop="sub_asset_type_label"
      v-if="type !== 'enterprise'"
      label="Asset Level 2"
      width="125"
      fixed="left"
    >
      <template #default="scope">
        <el-select v-model="scope.row.sub_asset_type_code" v-if="scope.row.isEdit" placeholder="Select" size="small">
          <el-option v-for="item in subAssetTypesList" :key="item.code" :label="item.label" :value="item.code" />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column prop="risk_statement"
      label="Risk Statement"
      :width="type === 'enterprise' ? 330 : 230"
      fixed="left"
    >
      <template #default="scope">
        <el-input v-model="scope.row.risk_statement" size="small" v-if="scope.row.isEdit" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }">
        </el-input>
      </template>
    </el-table-column>
    <el-table-column prop="consequence_areas_labels"
      label="Consequence Area"
      :width="type === 'enterprise' ? 197 : 150"
    >
      <template #default="scope">
        <el-select v-if="scope.row.isEdit" v-model="scope.row.consequence_areas" placeholder="Select" size="small" multiple collapse-tags collapse-tags-tooltip>
          <el-option v-for="item in consequenceCategoriesList" :key="item.code" :label="item.label" :value="item.code" />
        </el-select>
        <template v-else>
          <el-tag
            v-for="label in scope.row.consequence_areas_labels"
            :key="label"
            class="m-1"
            type="primary"
            effect="plain"
            size="small"
          >
            {{ label }}
          </el-tag>
        </template>
      </template>
    </el-table-column>
    <el-table-column prop="assumed_controls" label="Assumed Controls" width="200">
      <template #default="scope">
        <el-input v-model="scope.row.assumed_controls" size="small" v-if="scope.row.isEdit" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }">
        </el-input>
      </template>
    </el-table-column>
    <el-table-column prop="risk_type_label" label="Risk Type" width="100">
      <template #default="scope">
        <el-select v-model="scope.row.risk_type_code" v-if="scope.row.isEdit" placeholder="Select" size="small">
          <el-option v-for="item in riskTypesList" :key="item.code" :label="item.label" :value="item.code" />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column label="Risk assessment" align="center">
      <el-table-column label="Short term" align="center">
        <el-table-column prop="st_climate_risk_likelihood_label" label="Likelihood" width="120">
          <template #default="scope">
            <el-select v-model="scope.row.st_climate_risk_likelihood_code" v-if="scope.row.isEdit" placeholder="Select" size="small">
              <el-option v-for="item in assessmentLikelihoodList" :key="item.code" :label="item.label" :value="item.code" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="st_climate_risk_consequence_label" label="Consequence" width="120">
          <template #default="scope">
            <el-select v-model="scope.row.st_climate_risk_consequence_code" v-if="scope.row.isEdit" placeholder="Select" size="small">
              <el-option v-for="item in assessmentConsequencesList" :key="item.code" :label="item.label" :value="item.code" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="st_climate_risk_rating_label" label="Risk Rating" width="120">
          <template #default="scope">
            <RiskRating :code="scope.row.st_climate_risk_rating_code" :label="scope.row.st_climate_risk_rating_label"/>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Long term" align="center">
        <el-table-column prop="lt_climate_risk_likelihood_label" label="Likelihood" width="120">
          <template #default="scope">
            <el-select v-model="scope.row.lt_climate_risk_likelihood_code" v-if="scope.row.isEdit" placeholder="Select" size="small">
              <el-option v-for="item in assessmentLikelihoodList" :key="item.code" :label="item.label" :value="item.code" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="lt_climate_risk_consequence_label" label="Consequence" width="120">
          <template #default="scope">
            <el-select v-model="scope.row.lt_climate_risk_consequence_code" v-if="scope.row.isEdit" placeholder="Select" size="small">
              <el-option v-for="item in assessmentConsequencesList" :key="item.code" :label="item.label" :value="item.code" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="lt_climate_risk_rating_label" label="Risk Rating" width="120">
          <template #default="scope">
            <RiskRating :code="scope.row.lt_climate_risk_rating_code" :label="scope.row.lt_climate_risk_rating_label"/>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table-column>
    <el-table-column width="155" fixed="right" align="center">
      <template #header>
        Actions
      </template>
      <template #default="scope">
        <el-tooltip
          :content="`Check related ${type === 'enterprise' ? 'portfolio' : 'campus'} risks`"
          placement="bottom"
          effect="light"
        >
          <el-button
            v-if="type !== 'campus'"
            circle
            size="small"
            @click="toggleRowDetails(scope.row)"
          >
            <el-icon><QuestionFilled /></el-icon>
          </el-button>
        </el-tooltip>
        <el-tooltip :content="scope.row.isEdit ? 'Discard Edit' : 'Edit Details'" placement="bottom" effect="light">
          <el-button type="primary" size="small" @click="editItem(scope.row)" circle>
            <i class="fa fa-edit large"></i>
          </el-button>
        </el-tooltip>
        <el-tooltip content="Save Details" placement="bottom" v-if="scope.row.isEdit" effect="light">
          <el-button type="success" size="small" @click="saveItem(scope.row)" circle>
            <i class="fa fa-save large"></i>
          </el-button>
        </el-tooltip>
        <el-tooltip content="Delete" placement="bottom" effect="light">
          <el-button v-if="userIsAdmin" type="danger" size="small" @click="deleteItem(scope.row)" circle>
            <i class="fa fa-trash large"></i>
          </el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
  <el-row>
    <el-col :span="12">
      <el-pagination
        background small
        :page-size="parseInt(perPage ? perPage : 1)"
        layout="total, prev, pager, next"
        :total="craList.length" :default-current-page="1"
        @current-change="selectedPage"
        style="margin-top:1rem"
      />
    </el-col>
    <el-col :span="12" align="right">
      <el-tooltip content="Add New Risk Entry" placement="bottom" effect="light">
        <el-button color="#E4610F" class="mt-2" size="large" @click="dialogAddCampusCRAEntry = true" circle>
          <i class="fa fa-plus"></i>
        </el-button>
      </el-tooltip>
      <el-tooltip content="Download" placement="bottom" effect="light">
        <el-button @click="handleOnclickDownload" type="primary" class="mt-2" size="large" circle>
          <i class="fa-solid fa-download"></i>
        </el-button>
      </el-tooltip>
    </el-col>
  </el-row>

  <el-dialog v-model="dialogAddCampusCRAEntry" title="Shipping address" draggable>
    <template #header>
      <h4>Create new risk entry</h4>
    </template>
    <el-form :model="craForm" label-width="auto" label-position="left" size="small">
      <el-form-item label="Enterprise" v-if="type !== 'enterprise'">
        <el-select v-model="craForm.enterpriseCode" value-key="id" placeholder="Select" filterable clearable class="dialog-select">
          <el-option v-for="item in enterpriseList" :key="item.id" :label="item.risk_statement" :value="item" class="dialog-select-option" />
        </el-select>
      </el-form-item>
      <template v-if="type === 'campus'">
        <el-form-item label="Portfolio" >
          <el-select v-model="craForm.portfolioCode" value-key="id" placeholder="Select" filterable clearable class="dialog-select">
            <el-option v-for="item in portfolioList" :key="item.id" :label="item.risk_statement" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="Region">
          <el-select v-model="craForm.regionCode" placeholder="Select" filterable clearable class="dialog-select">
            <el-option v-for="item in regionsList" :key="item.code" :label="item.label" :value="item.code" />
          </el-select>
        </el-form-item>
        <el-form-item label="Campus">
          <el-select v-model="craForm.campusCode" placeholder="Select" filterable clearable class="dialog-select">
            <el-option v-for="item in getFilteredCampuses(craForm.regionCode)" :key="item.code" :label="item.label" :value="item.code" />
          </el-select>
        </el-form-item>
      </template>
      <template v-if="type !== 'enterprise'">
        <el-form-item label="Climate Hazard">
          <el-select v-model="craForm.climateHazardCodes" placeholder="Select" size="small" filterable clearable multiple collapse-tags collapse-tags-tooltip class="dialog-select">
            <el-option v-for="item in climateHazardsList" :key="item.code" :label="item.label" :value="item.code" />
          </el-select>
        </el-form-item>
        <el-form-item label="Asset Level 2">
          <el-select v-model="craForm.subAssetTypeCode" placeholder="Select" filterable clearable class="dialog-select">
            <el-option v-for="item in subAssetTypesList" :key="item.code" :label="item.label" :value="item.code" />
          </el-select>
        </el-form-item>
      </template>
      <el-form-item label="Risk Statement">
        <el-input v-model="craForm.riskStatement" type="textarea" />
      </el-form-item>
      <el-form-item label="Consequence Area">
        <el-select v-model="craForm.consequenceCategoryCodes" placeholder="Select" size="small" filterable clearable multiple collapse-tags collapse-tags-tooltip class="dialog-select">
          <el-option v-for="item in consequenceCategoriesList" :key="item.code" :label="item.label" :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="Assumed Controls">
        <el-input v-model="craForm.assumedControls" type="textarea" />
      </el-form-item>
      <el-form-item label="Risk Type" class="pb-3" v-if="type !== 'region'">
        <el-select v-model="craForm.riskTypeCode" placeholder="Select" filterable clearable>
          <el-option v-for="item in riskTypesList" :key="item.code" :label="item.label" :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="Critical Campuses" class="pb-3" v-else>
        <el-select v-model="craForm.criticalCampusCodes" placeholder="Select" multiple>
          <el-option v-for="item in campusList" :key="item.code" :label="item.label" :value="item.code" />
        </el-select>
      </el-form-item>
      <el-divider />
      <el-text class="mx-1" size="large">Risk Assessment</el-text>
      <el-form-item label="Short Term - Likelihood" class="pt-3">
        <el-radio-group v-model="craForm.riskAssessmentLikelihoodSTLabel" fill="#E4610F">
          <el-radio-button v-for="item in assessmentLikelihoodList" :key="item.code" :label="item.label" />
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Short Term - Consequence">
        <el-radio-group v-model="craForm.riskAssessmentConsequenceSTLabel" fill="#E4610F">
          <el-radio-button v-for="item in assessmentConsequencesList" :key="item.code" :label="item.label" />
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Short Term - Risk Rating">
          <RiskRating :code="craForm.riskAssessmentRatingSTCode" :label="craForm.riskAssessmentRatingSTLabel"/>
      </el-form-item>
      <el-form-item label="Long Term - Likelihood" class="pt-2">
        <el-radio-group v-model="craForm.riskAssessmentLikelihoodLTLabel" fill="#E4610F">
          <el-radio-button v-for="item in assessmentLikelihoodList" :key="item.code" :label="item.label" />
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Long Term - Consequence">
        <el-radio-group v-model="craForm.riskAssessmentConsequenceLTLabel" fill="#E4610F">
          <el-radio-button v-for="item in assessmentConsequencesList" :key="item.code" :label="item.label" />
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Long Term - Risk Rating">
          <RiskRating :code="craForm.riskAssessmentRatingLTCode" :label="craForm.riskAssessmentRatingLTLabel"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogAddCampusCRAEntry = false" color="#E4610F" plain>Cancel</el-button>
        <el-button @click="onSubmit" color="#E4610F">Save</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="showSelectedRowDetailsModal" class="childRiskSummaryModal" style="width: 75%">
    <ChildRiskSummary :selected-row="selectedRow" :type="type"/>
  </el-dialog>
</template>

<script>
/*eslint-disable */
import { ElMessage, ElMessageBox } from "element-plus";
import { ElNotification } from 'element-plus'
import {
  Check,
  Delete,
  Edit,
  QuestionFilled,
} from '@element-plus/icons-vue'
import axios from "axios";
import { baseApiUrl } from "../../config.js";
import { climateRiskMixin } from "../mixins/climateRiskMixin.js";
import ChildRiskSummary from "./ChildRiskSummary.vue";
import RiskRating from "./RiskRating.vue";

export default {
  name: 'RiskTable',
  components: {
    Check,
    ChildRiskSummary,
    Delete,
    Edit,
    QuestionFilled,
    RiskRating,
  },
  emits: ["fetchCRA"],
  props: ["filters", "initialCRAList", "initialLoadstate", "type"],
  mixins: [climateRiskMixin],
  data() {
    return {
      craForm: {
        campusCode: this.currentCampusCode,
        enterpriseCode: '',
        portfolioCode: '',
        climateHazardCode: '',
        subAssetTypeCode: '',
        riskStatement: '',
        consequenceCategoryCode: '',
        assumedControls: '',
        riskTypeCode: '',
        riskAssessmentLikelihoodSTCode: '',
        riskAssessmentLikelihoodSTLabel: '',
        riskAssessmentConsequenceSTCode: '',
        riskAssessmentConsequenceSTLabel: '',
        riskAssessmentRatingSTCode: '',
        riskAssessmentRatingSTLabel: '',
        riskAssessmentLikelihoodLTCode: '',
        riskAssessmentLikelihoodLTLabel: '',
        riskAssessmentConsequenceLTCode: '',
        riskAssessmentConsequenceLTLabel: '',
        riskAssessmentRatingLTCode: '',
        criticalCampusCodes: [],
      },
      windowHeight: 0,
      screenFactor: 0.55,
      dialogAddCampusCRAEntry: false,
      loadState: false,
      selectedCampus: null,
      craList: [],
      perPage: 20,
      currentPage: 1,
      selectedRow: {},
      showSelectedRowDetailsModal: false,
    }
  },
  computed: {
    userIsAdmin: {
      get() {
        return this.$store.state.mainModule.userIsAdmin;
      }
    },
    campusList: {
      get() {
        const stateCampusList = this.$store.state.climateRiskModule.campusList
        return stateCampusList
          ? stateCampusList.sort((a, b) => {
            if (a.code > b.code) {
              return 1
            } else if (a.code < b.code) {
              return -1
            } else {
              return 0
            }
          })
          : []
      }
    },
    enterpriseList: {
      get() {
        const eList = this.$store.state.climateRiskModule.enterpriseList
        return eList
      }
    },
    portfolioList: {
      get() {
        const pList = this.$store.state.climateRiskModule.portfolioList
        return pList
      }
    },
    regionsList: {
      get() {
        return this.$store.state.climateRiskModule.regionsList
      }
    },
    currentCampusCode: {
      get() {
        return this.$store.state.climateRiskModule.currentCampus ? this.$store.state.climateRiskModule.currentCampus : "albury"
      }
    },
    climateHazardsList: {
      get() {
        return this.$store.state.climateRiskModule.climateHazardsList
      }
    },
    subAssetTypesList: {
      get() {
        return this.$store.state.climateRiskModule.subAssetTypesList
      }
    },
    consequenceCategoriesList: {
      get() {
        return this.$store.state.climateRiskModule.consequenceCategoriesList
      }
    },
    riskTypesList: {
      get() {
        return this.$store.state.climateRiskModule.riskTypesList
      }
    },
    assessmentLikelihoodList: {
      get() {
        return this.$store.state.climateRiskModule.assessmentLikelihoodList
      }
    },
    assessmentConsequencesList: {
      get() {
        return this.$store.state.climateRiskModule.assessmentConsequencesList
      }
    },
    pageSelect: function () {
      let pageList = [];
      let newFilteredList = [].concat(this.craList);
      let itemsPerPage = this.perPage ? this.perPage : 1
      for (let i = 0; i < this.craList.length / itemsPerPage; i++) {
        pageList.push(newFilteredList.splice(0, itemsPerPage));
      }
      return pageList[this.currentPage - 1];
    },
    currentRegionCode: {
      get() {
        return this.$store.state.climateRiskModule.currentRegion || "north"
      }
    },
  },
  watch: {
    'craList': {
      handler(newValue, oldValue) {
        newValue.forEach((item) => {
          // climateRiskMixin
          this.computeRiskRatingList(item, "climate risk", "st")
          this.computeRiskRatingList(item, "climate risk", "lt")
        })
      },
      deep: true
    },
    'craForm': {
      handler(newValue, oldValue) {
        let item = newValue
        // climateRiskMixin
        this.computeRiskRatingForm(item, "ST")
        this.computeRiskRatingForm(item, "LT")
      },
      deep: true
    },
    initialCRAList: {
      handler(newValue) {
        this.craList = newValue;
      }
    },
    initialLoadstate: {
      handler(newValue) {
        this.loadState = newValue;
      }
    },
    'craForm.enterpriseCode': {
      handler(newValue) {
        const item = newValue
        this.$store.commit('climateRiskModule/CLEAR_PORTFOLIO_LIST')
        this.craForm.portfolioCode = []
        if (item.id) {
          const params = {
            id: item.id
          }
          this.$store.dispatch('climateRiskModule/GET_PORTFOLIO', params)
        }
      }
    },
    'craForm.regionCode': {
      handler(newValue) {
        // Clear the selected campus when the region changes
        this.craForm.campusCode = ''
      }
    },
  },
  methods: {
    setUpCRAList: function () {
      const current = this
      current.loadState = true
      let code = current.currentCampusCode
      if (current.type === "region") {
        code = current.currentRegionCode
      }
      current.$emit("fetchCRA", code)
    },
    selectedPage: function (pageNumber) {
      const current = this;
      current.currentPage = pageNumber;
    },
    editItem: function (item) {
      const current = this
      current.craList.filter(obj => obj.id == item.id)[0].isEdit = !current.craList.filter(obj => obj.id == item.id)[0].isEdit
      if (!current.craList.filter(obj => obj.id == item.id)[0].isEdit) {
        current.setUpCRAList()
      }
    },
    saveItem: function (item) {
      const current = this
      current.craList.filter(obj => obj.id == item.id)[0].isEdit = !current.craList.filter(obj => obj.id == item.id)[0].isEdit
      current.loadState = true
      let data = {
        id: item.id,
        subAssetTypeCode: item.sub_asset_type_code,
        climateHazardCode: item.climate_variables.join(", "),
        riskTypeCode: item.risk_type_code,
        consequenceCategoryCode: item.consequence_areas.join(", "),
        risk_statement: item.risk_statement,
        assumed_controls: item.assumed_controls,
        stLikelihoodCode: item.st_climate_risk_likelihood_code,
        stConsequenceCode: item.st_climate_risk_consequence_code,
        stRiskRatingCode: item.st_climate_risk_rating_code,
        ltLikelihoodCode: item.lt_climate_risk_likelihood_code,
        ltConsequenceCode: item.lt_climate_risk_consequence_code,
        ltRiskRatingCode: item.lt_climate_risk_rating_code,
        region: item.region_code,
        campus: item.campus_code,
        type: current.type,
      }
      this.$store.dispatch('climateRiskModule/UPDATE_CRA_ENTRY', data).then(() => {
        ElNotification({
          title: 'Risk Entry',
          message: 'Updated Successfully',
          type: 'success',
        })
        current.setUpCRAList()
        current.loadState = false
      })
    },
    deleteItem: function (item) {
      const current = this
      let data = {
        id: item.id,
        type: current.type,
      }
      ElMessageBox.confirm(
        "Are you sure you want to delete this entry?",
        "",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "error",
          confirmButtonClass: "error",
        }
      )
        .then(() => {
          this.$store.dispatch('climateRiskModule/DELETE_CRA_ENTRY', data).then(() => {
            current.setUpCRAList()
            ElNotification({
              title: 'Risk Entry',
              message: 'Deleted Successfully',
              type: 'success',
            })

          })
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Deletion canceled",
          });
        });
    },
    onSubmit: function () {
      const current = this
      const data = {
        ...current.craForm,
        type: current.type,
      }
      this.$store.dispatch('climateRiskModule/SAVE_CRA_ENTRY', data).then(() => {
        ElNotification({
          title: 'Risk Entry',
          message: 'Updated Successfully',
          type: 'success',
        })
        current.setUpCRAList()
        current.dialogAddCampusCRAEntry = false
      })
    },
    handleOnclickDownload: function () {
      let reqUrl = "";
      let filename = "";
      function capitalizeFirstLetter (word) {
        // e.g.: from campus to Campus
        const firstLetter = word.charAt(0)
        const firstLetterCap = firstLetter.toUpperCase()
        const remainingLetters = word.slice(1)
        const capitalizedWord = firstLetterCap + remainingLetters
        return capitalizedWord
      }

      filename = capitalizeFirstLetter(this.type) + "Report.xlsx"
      reqUrl = `${this.type}-risks/download`
      const url = baseApiUrl + reqUrl;
      axios.get(url, { params: this.filters, responseType: 'blob' }).then(result => {
        const type = result.headers["content-type"];
        const link = document.createElement("a");
        link.style = "display: none"

        const blob = new Blob(
          [result.data],
          { type }
        )
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.download = filename
        link.click()
        window.URL.revokeObjectURL(url)
      })
    },
    tableRowClassName: function ({ row, rowIndex }) {
      // Add a custom class based on your condition
      if (row.isEdit) {
        return 'warning-row'; // You can define any class name here
      }
      return '';
    },
    handleRegionChange(row) {
      // Clear the selected campus when the region changes
      row.campus_code = '';
    },
    getFilteredCampuses(region) {
      // Return the list of campuses that belong to the selected region
      return this.campusList.filter(campus => campus.region_code === region);
    },
    handleOnChangeSelectedRow(row) {
      this.selectedRow = row
    },
    toggleRowDetails(selectedRow) {
      this.selectedRow = selectedRow
      this.showSelectedRowDetailsModal = !this.showSelectedRowDetailsModal
    }
  },
  mounted() {
    const current = this;
    current.windowHeight = window.innerHeight
    window.onresize = () => {
      current.windowHeight = window.innerHeight
    }
  },
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}

.arc-orange-font {
  color: #E4610F
}

.arc-light-orange-font {
  color: #EB894B
}

.arc-primary-font {
  color: #0C0808
}

.custom-icon-space {
  flex: none;
  margin-right: 10px;
}

.custom-iframe-powerbi {
  min-height: 70vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none;
  overflow: hidden;
}
</style>
<style>
.custom-tbl-header {
  color: #E4610F !important;
  /* background: #FFF !important; */
}

.el-pagination.is-background .btn-next.is-active,
.el-pagination.is-background .btn-prev.is-active,
.el-pagination.is-background .el-pager li.is-active {
  background-color: #E4610F !important;
}

.el-pager li:hover {
  color: #E4610F
}

.dialog-select {
  width: 100%;
}

.el-select-dropdown {
  width: 500px;
}

.el-select-dropdown__item {
  overflow: visible;
}

.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
  --el-table-tr-bg-color: rgba(238, 238, 238, 0.4);
}

.childRiskSummaryModal {
  width: 30%;
}

.el-table .cell {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
</style>